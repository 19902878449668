import { useCreatePoolStore } from '@store/permissionlessCreatePoolStore'
import { MarketStats } from 'components/Synthetics/MarketStats/MarketStats'
import { MarketInfo, useMarketTokensData } from 'domain/synthetics/markets'
import { useMarketTokensAPR } from 'domain/synthetics/markets/useMarketTokensAPR'
import { getTokenData } from 'domain/synthetics/tokens'

const PoolParameters = ({
  currentMarketInfo,
}: {
  currentMarketInfo: MarketInfo
}) => {
  const { marketsInfoDataStore } = useCreatePoolStore()
  const { marketTokensData: depositMarketTokensData } = useMarketTokensData({
    isDeposit: true,
  })

  const { marketsTokensAPRData, marketsTokensIncentiveAprData } =
    useMarketTokensAPR()

  const marketToken = getTokenData(
    depositMarketTokensData,
    currentMarketInfo.marketTokenAddress,
  )

  return (
    <div className="py-4">
      <MarketStats
        marketsTokensAPRData={marketsTokensAPRData}
        marketsTokensIncentiveAprData={marketsTokensIncentiveAprData}
        marketTokensData={depositMarketTokensData}
        marketsInfoData={marketsInfoDataStore.marketsInfoData}
        marketInfo={currentMarketInfo}
        marketToken={marketToken}
      />
    </div>
  )
}

export default PoolParameters
