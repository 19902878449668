import TableRowAccordian from '@components/TableRowAccordian'
import { useCreatePoolStore } from '@store/permissionlessCreatePoolStore'
import { MarketsInfoData } from 'domain/synthetics/markets'
import useBreakpoints from 'hooks/useBreakpoints'
import {
  PoolContractsInfo,
  PoolGeneralInfo,
  PoolMarketParameters,
} from './index'
import PoolParameters from './PoolParameters'

const PoolPage = ({
  marketsInfoData,
}: {
  marketsInfoData: MarketsInfoData
}) => {
  const { below } = useBreakpoints()
  const { marketDetailAddress: selectedPool } = useCreatePoolStore()
  const currentMarketInfo = Object.values(marketsInfoData).filter(
    (item) => item.marketTokenAddress === selectedPool,
  )[0]

  if (below.lg) {
    return (
      <div>
        <TableRowAccordian
          VisibleContent={
            <h1 className="py-2 text-sm font-semibold capitalize leading-6 text-th-fgd-1">
              Pool Parameters
            </h1>
          }
          HiddenContent={
            <PoolParameters currentMarketInfo={currentMarketInfo} />
          }
          panelClass="border-b border-th-input-border"
          buttonClass="border-b border-th-input-border"
        />
        <TableRowAccordian
          VisibleContent={
            <h1 className="py-2 text-sm font-semibold capitalize leading-6 text-th-fgd-1">
              General
            </h1>
          }
          HiddenContent={
            <PoolGeneralInfo currentMarketInfo={currentMarketInfo} />
          }
          panelClass="border-b border-th-input-border"
          buttonClass="border-b border-th-input-border"
        />
        <TableRowAccordian
          VisibleContent={
            <h1 className="py-2 text-sm font-semibold capitalize leading-6 text-th-fgd-1">
              Market Parameters
            </h1>
          }
          HiddenContent={
            <PoolMarketParameters currentMarketInfo={currentMarketInfo} />
          }
          panelClass="border-b border-th-input-border"
          buttonClass="border-b border-th-input-border"
        />
        <TableRowAccordian
          VisibleContent={
            <h1 className="py-2 text-sm font-semibold capitalize leading-6 text-th-fgd-1">
              Contracts
            </h1>
          }
          HiddenContent={
            <PoolContractsInfo currentMarketInfo={currentMarketInfo} />
          }
          buttonClass="border-b border-th-input-border"
        />
      </div>
    )
  }

  return (
    <div className="flex justify-between gap-12">
      <div className="flex basis-1/2 flex-col">
        <div>
          <h1 className="text-xl font-bold text-th-fgd-2">General</h1>
          <PoolGeneralInfo currentMarketInfo={currentMarketInfo} />
        </div>
        <PoolParameters currentMarketInfo={currentMarketInfo} />
      </div>

      <div className="flex basis-1/2 flex-col gap-8">
        <div className="flex flex-col gap-2">
          <h1 className="text-xl font-bold text-th-fgd-2">Market Parameters</h1>
          <PoolMarketParameters currentMarketInfo={currentMarketInfo} />
        </div>
        <div className="flex flex-col gap-2">
          <h1 className="text-xl font-bold text-th-fgd-2">Contracts</h1>
          <PoolContractsInfo currentMarketInfo={currentMarketInfo} />
        </div>
      </div>
    </div>
  )
}

export default PoolPage
