import { MarketsInfoData } from 'domain/synthetics/markets'
import { useStepper } from 'headless-stepper'

import { useMarketsRequest } from '@store/marketsStore'
import { useCreatePoolStore } from '@store/permissionlessCreatePoolStore'
import clsx from 'clsx'
import { IS_VERBOSE } from 'config/development'
import { helperToast } from 'gmx/lib/helperToast'
import Image from 'next/image'
import React, { useMemo } from 'react'
import { steps, stepsHeadings } from 'utils/permissionless'
import { ProgressBar, Step1, Step2, Step3, Step4 } from './index'
import useBreakpoints from 'hooks/useBreakpoints'

interface CreatePoolModalProps {
  handleClose: () => void
  marketsInfoData: MarketsInfoData
}

const CreatePoolModal = ({
  handleClose,
  marketsInfoData,
}: CreatePoolModalProps) => {
  const { below } = useBreakpoints()
  const { resetStore } = useCreatePoolStore()
  const { state, nextStep, prevStep, setStep } = useStepper({
    steps,
  })

  const onPoolCreationWaitingStep = useMemo(() => {
    return state.currentStep + 1 === state.totalSteps
  }, [state.currentStep, state.totalSteps])

  const modalCloseHandler = () => {
    if (onPoolCreationWaitingStep) {
      return
    } else {
      setStep(0)
      resetStore()
      handleClose()
    }
  }

  const _marketsData = useMarketsRequest(true)

  const handleCreation = React.useCallback(
    async (createDepositTxn: () => Promise<any>) => {
      try {
        nextStep()
        await createDepositTxn()
      } catch (e) {
        // eslint-disable-next-line no-console
        IS_VERBOSE && console.log('Failed to create pool', e)
        helperToast.error('Failed to create pool')
        handleClose()
      }
      return
    },
    [handleClose, nextStep],
  )

  if (below.lg) {
    handleClose()
    return null
  }

  return (
    <div className={clsx(onPoolCreationWaitingStep ? 'pt-0' : 'pt-5')}>
      {!onPoolCreationWaitingStep && (
        <div className="relative mx-4 flex flex-col border-b border-th-input-border pb-4">
          <Image
            alt=""
            className="absolute right-0 top-0 z-0 cursor-pointer"
            src="/icons/close-icon-modal.svg"
            width={14}
            height={14}
            onClick={modalCloseHandler}
          />
          <span className="text-base font-bold text-th-fgd-1">
            {stepsHeadings[state.currentStep].title}
          </span>
          <span className="text-sm font-normal leading-6 text-th-fgd-2">
            {stepsHeadings[state.currentStep].subTitle}
          </span>
        </div>
      )}
      <div
        className={clsx(
          'relative flex h-full flex-col justify-between',
          !onPoolCreationWaitingStep && 'pt-4',
        )}
      >
        {!onPoolCreationWaitingStep ? (
          <div className="mx-4 mb-4 h-full space-y-10">
            <ProgressBar
              currentStep={state.currentStep}
              totalSteps={state.totalSteps}
            />

            <div className="h-[calc(100%-72px)]">
              {state.currentStep === 0 && (
                <Step1
                  nextStep={nextStep}
                  marketsInfoData={marketsInfoData}
                  marketsData={_marketsData || {}}
                />
              )}
              {state.currentStep === 1 && (
                <Step2
                  prevStep={prevStep}
                  nextStep={nextStep}
                  marketsData={_marketsData || {}}
                />
              )}
              {state.currentStep === 2 && (
                <Step3
                  handleCreation={handleCreation}
                  prevStep={prevStep}
                  marketsData={_marketsData || {}}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="h-full">
            <Step4 handleClose={handleClose} marketsData={_marketsData || {}} />
          </div>
        )}
      </div>
      <div className="absolute bottom-0 -z-10 h-[266px] w-full rounded-b-xl">
        <Image
          alt="create new pool"
          src="/icons/create-pool-bg-vector.svg"
          layout="fill"
        />
      </div>
    </div>
  )
}

export default CreatePoolModal
