import { useMemo } from 'react'

interface ProgressBarProps {
  currentStep: number
  totalSteps: number
}

const ProgressBar = ({ currentStep, totalSteps }: ProgressBarProps) => {
  const initialWidth = 2
  const progressBarWidth = useMemo(() => {
    return (
      currentStep * ((100 - initialWidth) / (totalSteps - 2)) + initialWidth
    )
  }, [currentStep, totalSteps])

  return (
    <div className="py-3">
      <div className="h-2 w-full flex-shrink-0 rounded-lg bg-th-input-border">
        <div
          className="transition-al h-full rounded-lg border border-th-input-border-secondary bg-th-active shadow-primary-color duration-300 ease-in-out"
          style={{ width: `${progressBarWidth}%` }}
        />
      </div>
    </div>
  )
}

export default ProgressBar
