import { useFavoritesStore } from '@store/favoriteMarketsStore'
import { useFavoritePoolsStore } from '@store/favoritePoolsStore'
import StarIconFilled from 'public/icons/star-filled.svg'
import StarIconLine from 'public/icons/star-line.svg'
import { useMemo } from 'react'

type FavoriteButtonProps = {
  item: string
  type: 'market' | 'pool'
}

const FavoriteButton = ({ item, type }: FavoriteButtonProps) => {
  // Call both hooks unconditionally
  const marketFavorites = useFavoritesStore((s) => s.symbols)
  const poolFavorites = useFavoritePoolsStore((s) => s.addresses)

  const addMarketToFavorites = useFavoritesStore((s) => s.addSymbol)
  const addPoolToFavorites = useFavoritePoolsStore((s) => s.addAddress)

  const removeMarketFromFavorites = useFavoritesStore((s) => s.removeSymbol)
  const removePoolFromFavorites = useFavoritePoolsStore((s) => s.removeAddress)

  const { favoriteItems, addToFavorites, removeFromFavorites } = useMemo(() => {
    const isMarket = type === 'market'
    return {
      favoriteItems: isMarket ? marketFavorites : poolFavorites,
      addToFavorites: isMarket ? addMarketToFavorites : addPoolToFavorites,
      removeFromFavorites: isMarket
        ? removeMarketFromFavorites
        : removePoolFromFavorites,
    }
  }, [
    addMarketToFavorites,
    addPoolToFavorites,
    marketFavorites,
    poolFavorites,
    removeMarketFromFavorites,
    removePoolFromFavorites,
    type,
  ])

  return favoriteItems?.find((name: string) => name === item) ? (
    <button
      className="flex items-center justify-center text-th-active focus-visible:text-th-fgd-3 md:hover:text-th-fgd-3"
      onClick={() => removeFromFavorites(item)}
    >
      <StarIconFilled />
    </button>
  ) : (
    <button
      className="flex items-center justify-center text-th-fgd-3 focus-visible:text-th-active md:hover:text-th-active"
      onClick={() => addToFavorites(item)}
    >
      <StarIconLine />
    </button>
  )
}

export default FavoriteButton
