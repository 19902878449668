import commonStore from '@store/commonStore'
import {
  PoolViews,
  useCreatePoolStore,
} from '@store/permissionlessCreatePoolStore'
import {
  Mode,
  Operation,
} from 'components/Synthetics/GmSwap/GmSwapBox/GmSwapBox'
import { MarketsInfoData } from 'domain/synthetics/markets'
import { TokensData } from 'domain/synthetics/tokens'
import { formatTokenAmount } from 'gmx/lib/numbers'
import useBreakpoints from 'hooks/useBreakpoints'
import usePerpsPoolData, { PoolDataType } from 'hooks/usePerpsPoolData'
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import { useAccount } from 'wagmi'
import { initilaFilterData } from '../../config/earn'
import CreatePoolModal from './CreateNewPool/CreatePoolModal'
import EarnHeader from './EarnHeader'
import EarnSwapBoxWithStats from './EarnSwapBoxWithStats'
import RenderEarnPageTable from './RenderEarnPageTable'
import RenderGmListCards from './RenderGmListCards'

interface Props {
  marketsInfoData: MarketsInfoData
  tokensData: TokensData | undefined
  depositMarketTokensData?: TokensData
  shouldDisableValidation: boolean
  operation: Operation
  setOperation: Dispatch<SetStateAction<Operation>>
  mode: Mode
  setMode: Dispatch<SetStateAction<Mode>>
}

const EarnHomePage = ({
  tokensData,
  depositMarketTokensData,
  shouldDisableValidation,
  marketsInfoData,
  mode,
  operation,
  setMode,
  setOperation,
}: Props) => {
  const { address } = useAccount()
  const { below } = useBreakpoints()
  const formattedPoolsData = usePerpsPoolData()
  const { poolView } = useCreatePoolStore()
  const { appliedFilter, setAppliedFilter } = commonStore()

  const [searchQuery, setSearchQuery] = useState<string>('')
  const [createNewPoolModalOpen, setCreateNewPoolModalOpen] =
    useState<boolean>(false)

  const filterDataByMarket = useCallback(
    (data: PoolDataType, query: string) =>
      data.marketIndexName.toLowerCase().includes(query.toLowerCase()) ||
      data.token.address.toLowerCase().includes(query.toLowerCase()),
    [],
  )

  const filteredPoolsData = useMemo(() => {
    return formattedPoolsData
      .filter((data) => {
        if (appliedFilter === 'ALL') {
          return true
        }
        if (appliedFilter === 'MY POOLS') {
          return (
            formatTokenAmount(data.balance, data.token.decimals, 'RP', {
              useCommas: true,
              displayDecimals: 2,
              fallbackToZero: true,
            }) !== '0.00 RP'
          )
        }
        if (appliedFilter === 'LABS') {
          return data.market?.firstLp === address
        }
        if (appliedFilter === 'RP POOLS') {
          return data.market?.firstLp !== address
        }
        if (appliedFilter === 'L1 / L2') {
          return ['L1', 'L2'].includes(data.indexToken.tag || '')
        }
        if (appliedFilter === 'MEMES') {
          return data.indexToken.tag === 'MEME'
        }
        if (appliedFilter === 'DEFI') {
          return data.indexToken.tag === 'DEFI'
        }
        return false
      })
      .filter(
        (item) =>
          searchQuery.trim() === '' || filterDataByMarket(item, searchQuery),
      )
  }, [
    formattedPoolsData,
    appliedFilter,
    searchQuery,
    address,
    filterDataByMarket,
  ])

  const updatedFilterCount = useMemo(() => {
    return Object.entries(initilaFilterData).map(([key, filter]) => {
      let count = 0

      switch (key) {
        case 'ALL':
          count = formattedPoolsData.length
          break
        case 'MY POOLS':
          count = formattedPoolsData.filter(
            (d) =>
              formatTokenAmount(d.balance, d.token.decimals, 'RP', {
                useCommas: true,
                displayDecimals: 2,
                fallbackToZero: true,
              }) !== '0.00 RP',
          ).length
          break
        case 'LABS':
          count = formattedPoolsData.filter(
            (d) => d.market?.firstLp === address,
          ).length
          break
        case 'RP POOLS':
          count = formattedPoolsData.filter(
            (d) => d.market?.firstLp !== address,
          ).length
          break
        case 'L1L2':
          count = formattedPoolsData.filter((d) =>
            ['L1', 'L2'].includes(d.indexToken.tag || ''),
          ).length
          break
        case 'MEMES':
          count = formattedPoolsData.filter(
            (d) => d.indexToken.tag === 'MEME',
          ).length
          break
        case 'DEFI':
          count = formattedPoolsData.filter(
            (d) => d.indexToken.tag === 'DEFI',
          ).length
          break
        default:
          count = 0
      }

      return { label: filter.label, count }
    })
  }, [formattedPoolsData, address])

  if (below.lg) {
    return (
      <div className="flex h-full w-full flex-col">
        <EarnHeader
          appliedFilter={appliedFilter}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          updatedFilterCount={updatedFilterCount}
          handleTokenFilterChange={(val: string) => {
            setAppliedFilter(val)
          }}
          showCreatePoolModal={() => setCreateNewPoolModalOpen(true)}
        />
        <div className="flex-1 overflow-y-auto">
          {poolView === PoolViews.Table ? (
            <RenderEarnPageTable
              marketTokensData={depositMarketTokensData}
              gmList={filteredPoolsData}
            />
          ) : (
            <RenderGmListCards
              marketTokensData={depositMarketTokensData}
              gmList={filteredPoolsData}
            />
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="relative flex h-full w-full">
      <div className="flex h-full w-[calc(100%-396px)] flex-col">
        <EarnHeader
          appliedFilter={appliedFilter}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          updatedFilterCount={updatedFilterCount}
          handleTokenFilterChange={(val: string) => {
            setAppliedFilter(val)
          }}
          showCreatePoolModal={() => setCreateNewPoolModalOpen(true)}
        />
        <div className="flex-1 overflow-y-auto">
          <RenderEarnPageTable
            marketTokensData={depositMarketTokensData}
            gmList={filteredPoolsData}
          />
        </div>
      </div>

      <div className="relative flex w-[396px] shrink-0 flex-col overflow-scroll border-l border-th-input-border-secondary">
        {createNewPoolModalOpen ? (
          <CreatePoolModal
            marketsInfoData={marketsInfoData}
            handleClose={() => setCreateNewPoolModalOpen(false)}
          />
        ) : (
          <EarnSwapBoxWithStats
            marketsInfoData={marketsInfoData}
            tokensData={tokensData}
            shouldDisableValidation={shouldDisableValidation}
            operation={operation}
            setOperation={setOperation}
            mode={mode}
            setMode={setMode}
          />
        )}
      </div>
    </div>
  )
}

export default EarnHomePage
