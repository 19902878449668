import { IS_VERBOSE } from 'config/development'
import { FAVORITE_POOLS_KEY } from 'utils/constants'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export type FavoritePoolsStore = {
  addresses: string[]
  addAddress: (addresses: string) => void
  removeAddress: (addresses: string) => void
  reset: () => void
}

export const useFavoritePoolsStore = create<FavoritePoolsStore>()(
  persist(
    (set) => ({
      addresses: [],
      addAddress: (addresses) => {
        set((state) => ({ addresses: [...state.addresses, addresses] }))
      },
      removeAddress: (addresses) => {
        set((state) => ({
          addresses: state.addresses.filter((s) => s !== addresses),
        }))
      },
      reset: () => {
        set({ addresses: [] })
      },
    }),
    {
      name: FAVORITE_POOLS_KEY,
      partialize: (state) => ({ addresses: state.addresses }),
      onRehydrateStorage: (state) => {
        // eslint-disable-next-line no-console
        IS_VERBOSE && console.log('[FavoritePoolsStore] Rehydrated', state)

        return (state, error) => {
          if (error) {
            IS_VERBOSE &&
              // eslint-disable-next-line no-console
              console.error('[FavoritePoolsStore] Error rehydrating', error)
            return { addresses: [] }
          } else {
            IS_VERBOSE &&
              // eslint-disable-next-line no-console
              console.log('[FavoritePoolsStore] Successfully rehydrated', state)
          }

          return
        }
      },
    },
  ),
)
