import clsx from 'clsx'
import { PropsWithChildren } from 'react'

type Props = {
  text: string
  subText?: string
  className?: string
}

const ExchangeInfoRowValue = ({
  text,
  subText,
  className,
}: PropsWithChildren<Props>) => {
  return (
    <div className={clsx('flex items-center', className)}>
      <p className="text-sm font-medium text-th-fgd-1">{text}</p>
      {subText && (
        <p className="w-32 text-end text-sm font-medium text-th-fgd-3">
          &nbsp;({subText})
        </p>
      )}
    </div>
  )
}

export default ExchangeInfoRowValue
