import clsx from 'clsx'
import { helperToast } from 'gmx/lib/helperToast'
import { PropsWithChildren } from 'react'

type CopyToClipboardProps = {
  text: string
  className?: string
}

const CopyToClipboard = ({
  text,
  children,
  className,
}: PropsWithChildren<CopyToClipboardProps>) => {
  const handleCopy = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    await navigator.clipboard.writeText(text)
    const toastId = Date.now()
    helperToast.success(<p>Copied!</p>, {
      toastId,
      autoClose: 1000,
      closeButton: false,
      style: { width: '150px', position: 'absolute', bottom: '0', right: '0' },
    })
  }

  return (
    <span
      className={clsx(
        'cursor-pointer transition duration-300 ease-out hover:opacity-70',
        className,
      )}
      onClick={handleCopy}
    >
      {children}
    </span>
  )
}

export default CopyToClipboard
