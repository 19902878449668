import { PRECISION } from 'gmx/lib/legacy'
import { formatAmount } from 'gmx/lib/numbers'
import { BigNumber } from 'ethers'

export function formatFactor(factor: BigNumber) {
  if (factor.eq(0)) {
    return '0'
  }

  if (factor.abs().gt(PRECISION.mul(1000))) {
    return factor.div(PRECISION).toString()
  }

  const trailingZeroes =
    factor
      .abs()
      .toString()
      .match(/^(.+?)(?<zeroes>0*)$/)?.groups?.zeroes?.length || 0
  const factorDecimals = 30 - trailingZeroes
  return formatAmount(factor, 30, factorDecimals)
}
