import {
  Mode,
  Operation,
} from 'components/Synthetics/GmSwap/GmSwapBox/GmSwapBox'
import { MarketsInfoData } from 'domain/synthetics/markets'
import { TokensData } from 'domain/synthetics/tokens'
import useBreakpoints from 'hooks/useBreakpoints'
import usePerpsPoolData from 'hooks/usePerpsPoolData'
import { Dispatch, SetStateAction } from 'react'
import EarnSwapBoxWithStats from './EarnSwapBoxWithStats'
import PoolPage from './PoolPage/PoolPage'
import { PoolPageHeader } from './PoolPage/PoolPageHeader'

interface Props {
  marketsInfoData: MarketsInfoData
  tokensData: TokensData | undefined
  shouldDisableValidation: boolean
  operation: Operation
  setOperation: Dispatch<SetStateAction<Operation>>
  mode: Mode
  setMode: Dispatch<SetStateAction<Mode>>
}

const EarnDetailsPage = ({
  marketsInfoData,
  mode,
  operation,
  setMode,
  setOperation,
  shouldDisableValidation,
  tokensData,
}: Props) => {
  const { below } = useBreakpoints()
  const formattedPoolsData = usePerpsPoolData()

  if (below.lg) {
    return (
      <div className="flex h-full w-full flex-col overflow-y-auto">
        <PoolPageHeader
          marketsInfoData={marketsInfoData}
          data={formattedPoolsData}
        />
        <div className="flex-1 overflow-y-auto">
          <div className="border-b border-th-input-border">
            <EarnSwapBoxWithStats
              marketsInfoData={marketsInfoData}
              tokensData={tokensData}
              shouldDisableValidation={shouldDisableValidation}
              operation={operation}
              setOperation={setOperation}
              mode={mode}
              setMode={setMode}
            />
          </div>

          <PoolPage marketsInfoData={marketsInfoData} />
        </div>
      </div>
    )
  }
  return (
    <div className={`flex h-full w-full justify-between`}>
      <div className="flex h-full w-full flex-col">
        <PoolPageHeader
          marketsInfoData={marketsInfoData}
          data={formattedPoolsData}
        />
        <div className="border-b border-th-input-border px-6 py-4">
          <PoolPage marketsInfoData={marketsInfoData} />
        </div>
      </div>

      <div className="relative flex w-full max-w-[396px] shrink-0 flex-col overflow-scroll border-l border-th-input-border-secondary">
        <EarnSwapBoxWithStats
          marketsInfoData={marketsInfoData}
          tokensData={tokensData}
          shouldDisableValidation={shouldDisableValidation}
          operation={operation}
          setOperation={setOperation}
          mode={mode}
          setMode={setMode}
        />
      </div>
    </div>
  )
}

export default EarnDetailsPage
