import Button from '@components/shared/Button'
import { useCreatePoolStore } from '@store/permissionlessCreatePoolStore'
import clsx from 'clsx'
import { CLOUD_FRONT_URL } from 'config/constants'
import { DepositStatus, useSyntheticsEvents } from 'context/SyntheticsEvents'
import { MarketsData } from 'domain/synthetics/markets'
import { useEffect, useState, useMemo } from 'react'

const Step4 = ({
  handleClose,
  marketsData,
}: {
  handleClose: () => void
  marketsData: MarketsData
}) => {
  const [isBtnDisabled, setBtnDisabled] = useState(true)

  const { tokenA, marketsInfoDataStore } = useCreatePoolStore()

  const selectedData = useMemo(
    () =>
      Object.values(marketsData).find(
        (item) => item.indexTokenAddress === tokenA,
      ),
    [marketsData, tokenA],
  )

  const { depositStatuses } = useSyntheticsEvents()

  const depositStatus = useMemo(
    () =>
      selectedData
        ? Object.values(depositStatuses).find(
            (item: DepositStatus) =>
              item.data &&
              item.data.marketAddress === selectedData.marketTokenAddress,
          )
        : null,
    [depositStatuses, selectedData],
  )

  useEffect(() => {
    if (depositStatus?.executedTxnHash) {
      setBtnDisabled(false)
    }
  }, [depositStatus])

  const showPoolClickHandler = () => {
    handleClose()
    marketsInfoDataStore.mutate()
  }

  return (
    <div className="flex h-full flex-col rounded-xl">
      <div className="flex-1 rounded-t-xl">
        <video
          autoPlay
          loop
          muted
          playsInline
          className="h-full w-full sm:object-cover"
          id="bg-video"
          src={`${CLOUD_FRONT_URL}/videos/pool-deploying.mp4`}
        />
      </div>
      <div className="flex flex-1 flex-col justify-between rounded-b-xl p-6 pt-4">
        <p className="text-center text-base font-normal text-th-fgd-2">
          Your pool will be deployed shortly.
          <br />
          Keep your umbrellas ready cuz it&apos;s gonna rain here.
        </p>

        <Button
          className={clsx(
            'mt-4 w-full',
            isBtnDisabled ? '!bg-th-bkg-3 !opacity-100' : '!bg-th-active',
          )}
          type="submit"
          onClick={showPoolClickHandler}
          disabled={isBtnDisabled}
          size="large"
        >
          <span
            className={clsx(
              'text-sm font-bold uppercase leading-[18px]',
              isBtnDisabled ? 'text-th-fgd-3' : 'text-th-fgd-1',
            )}
          >
            {isBtnDisabled ? 'Deploying....' : 'SHOW POOL'}
          </span>
        </Button>
      </div>
    </div>
  )
}

export default Step4
