import { useCreatePoolStore } from '@store/permissionlessCreatePoolStore'
import BuyInputSection from 'components/BuyInputSection/BuyInputSection'
import { DEFAULT_CHAIN_ID } from 'config/chains'
import { MarketsDataValue } from 'domain/synthetics/markets'
import { useTokenBalances } from 'domain/synthetics/tokens'
import { BigNumber } from 'ethers'
import { DUST_BNB } from 'gmx/lib/legacy'
import { formatAmountFree, formatTokenAmount } from 'gmx/lib/numbers'

interface Props {
  selectedData: MarketsDataValue | undefined
}

const AddTokensLiquidity = ({ selectedData }: Props) => {
  const {
    firstTokenInputValue,
    secondTokenInputValue,
    setSecondTokenInputValue,
    setFirstTokenInputValue,
    setFocusedInput,
  } = useCreatePoolStore()

  const { balancesData } = useTokenBalances(DEFAULT_CHAIN_ID)

  const getBalance = (tokenAddress?: string) => {
    const balance =
      tokenAddress && balancesData?.[tokenAddress]
        ? balancesData[tokenAddress]
        : BigNumber.from(0)

    return balance.toString()
  }

  return (
    <div className="flex w-full items-center gap-2">
      <BuyInputSection
        topLeftLabel="Add Liquidity"
        topRightLabel="Balance:"
        topRightValue={
          balancesData &&
          selectedData?.longToken &&
          balancesData[selectedData.longToken.address]
            ? `${formatTokenAmount(
                balancesData[selectedData.longToken.address],
                selectedData.longToken.decimals,
                '',
                {
                  useCommas: true,
                },
              )}`
            : '...'
        }
        onClickTopRightLabel={() =>
          setFirstTokenInputValue(getBalance(selectedData?.longToken?.address))
        }
        inputValue={firstTokenInputValue}
        onInputValueChange={(e) => {
          setFirstTokenInputValue(e.target.value)
          setFocusedInput('longCollateral')
        }}
        onClickMax={() => {
          if (
            balancesData &&
            selectedData?.longToken &&
            balancesData[selectedData.longToken.address]
          ) {
            const formattedGMBalance = formatAmountFree(
              balancesData[selectedData.longToken?.address],
              selectedData.longToken.decimals,
            )
            const finalGMBalance = formattedGMBalance
            setFirstTokenInputValue(finalGMBalance)
          }
        }}
        showMaxButton={false}
        containerClass="h-12"
      >
        <span className="text-base font-bold text-th-fgd-3">
          {selectedData?.longToken?.symbol.toUpperCase()}
        </span>
      </BuyInputSection>

      <BuyInputSection
        topLeftLabel=""
        topRightLabel="Balance:"
        topRightValue={
          balancesData &&
          selectedData?.shortToken &&
          balancesData[selectedData.shortToken.address]
            ? `${formatTokenAmount(
                balancesData[selectedData.shortToken.address],
                selectedData.shortToken.decimals,
                '',
                {
                  useCommas: true,
                },
              )}`
            : '...'
        }
        onClickTopRightLabel={() =>
          setSecondTokenInputValue(
            getBalance(selectedData?.shortToken?.address),
          )
        }
        inputValue={secondTokenInputValue}
        onInputValueChange={(e) => {
          setSecondTokenInputValue(e.target.value)
          setFocusedInput('shortCollateral')
        }}
        onClickMax={() => {
          if (
            balancesData &&
            selectedData?.shortToken &&
            balancesData[selectedData.shortToken.address]
          ) {
            const maxAvailableAmount = selectedData.shortToken.isNative
              ? balancesData[selectedData.shortToken.address].sub(
                  BigNumber.from(DUST_BNB).mul(2),
                )
              : balancesData[selectedData.shortToken.address]

            const formattedMaxAvailableAmount = formatAmountFree(
              maxAvailableAmount,
              selectedData.shortToken.decimals,
            )
            const finalAmount = formattedMaxAvailableAmount

            setSecondTokenInputValue(finalAmount)
          }
        }}
        showMaxButton={false}
        containerClass="h-12"
      >
        <span className="text-base font-bold text-th-fgd-3">
          {selectedData?.shortToken?.symbol.toUpperCase()}
        </span>
      </BuyInputSection>
    </div>
  )
}

export default AddTokensLiquidity
