import { MarketsData, MarketsInfoData } from 'domain/synthetics/markets/types'
import { TokensData } from 'domain/synthetics/tokens/types'
import { useMarketsInfoData, useMarketsRequest } from '@store/marketsStore'
import { useCreatePoolStore } from '@store/permissionlessCreatePoolStore'
import { useEffect } from 'react'

export type MarketsInfoResult = {
  marketsInfoData?: MarketsInfoData
  tokensData?: TokensData
  pricesUpdatedAt?: number
  marketsData: MarketsData
}

export function useMarketInfo(marketsAddresses: string[]): MarketsInfoResult {
  const setMarketsInfoData = useCreatePoolStore(
    (store) => store.setMarketsInfoData,
  )
  const _marketsInfoData = useCreatePoolStore((store) => store.marketsInfoData)

  const marketsData: MarketsData | undefined = useMarketsRequest(true)

  const { marketsInfoData, tokensData, pricesUpdatedAt } = useMarketsInfoData(
    marketsData,
    marketsAddresses,
    _marketsInfoData,
  )

  useEffect(() => {
    if (!marketsInfoData) {
      return
    }
    setMarketsInfoData(marketsInfoData)
  }, [marketsInfoData])

  return {
    marketsInfoData,
    tokensData,
    pricesUpdatedAt,
    marketsData: marketsData || {},
  }
}
