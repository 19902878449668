import CopyToClipboard from '@components/shared/CopyToClipboard'
import EmptyState from '@components/shared/EmptyState'
import RenderTokenIcon from '@components/shared/RenderTokenIcon'
import TokenFilters from '@components/shared/TokenFilters'
import { Listbox, Transition } from '@headlessui/react'
import clsx from 'clsx'
import SearchInput from 'components/SearchInput/SearchInput'
import { DEFAULT_CHAIN_ID } from 'config/chains'
import { TOKENS } from 'config/tokens'
import { MarketInfo, MarketsInfoData } from 'domain/synthetics/markets'
import { TokenData, TokensData } from 'domain/synthetics/tokens'
import { keyBy } from 'lodash'
import Image from 'next/image'
import ArrowDown from 'public/icons/arrow-down-grey.svg'
import { useCallback, useMemo, useState } from 'react'
import { getShorterAddress } from 'utils/formatting'
import { tokenPairsChipsIntialData } from 'utils/permissionless'

type Props = {
  label?: string
  className?: string
  selectedMarketAddress?: string
  selectedIndexName?: string
  markets: MarketInfo[]
  marketsInfoData?: MarketsInfoData
  marketTokensData?: TokensData
  showBalances?: boolean
  getMarketState?: (market: MarketInfo) => MarketState | undefined
  onSelectMarket: (market: MarketInfo) => void
  showAllPools?: boolean
  isBtnDisabled?: boolean
  chainId?: number
}

type MarketState = {
  disabled?: boolean
  message?: string
}

export function PoolSelector({
  selectedMarketAddress,
  markets,
  onSelectMarket,
  isBtnDisabled,
  chainId,
}: Props) {
  const [appliedTokenFilter, setAppliedTokenFilter] = useState(
    tokenPairsChipsIntialData['All'].label,
  )

  const [searchQuery, setSearchQuery] = useState('')

  const filteredMarkets = useMemo(() => {
    let filteredData = markets
    filteredData = filteredData?.filter(() => {
      switch (appliedTokenFilter) {
        case 'All':
          return true
        case 'Crypto':
          return true
        case 'Forex':
          return false
        default:
          return true
      }
    })

    if (searchQuery.trim() !== '') {
      filteredData = filteredData?.filter((item) => {
        const token = item.indexToken
        return (
          token.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          token.symbol.toLowerCase().includes(searchQuery.toLowerCase())
        )
      })
    }
    return filteredData
  }, [markets, appliedTokenFilter, searchQuery])

  const updateTokenFiltersCount = useMemo(() => {
    const updatedFilters = { ...tokenPairsChipsIntialData }
    Object.keys(updatedFilters).forEach((key) => {
      switch (key) {
        case 'All':
        case 'Crypto':
          updatedFilters[key].count = markets?.length || 0
          break
        case 'Forex':
          updatedFilters[key].count = 0
          break
        default:
          break
      }
    })
    return Object.keys(updatedFilters).map((key) => {
      return {
        label: updatedFilters[key].label,
        count: updatedFilters[key].count,
      }
    })
  }, [markets])

  const handleTokenFilterChange = useCallback((val: string) => {
    setAppliedTokenFilter(val)
  }, [])

  const mapTokens = keyBy(TOKENS[chainId || DEFAULT_CHAIN_ID], 'address')

  const indexSymbol = mapTokens[selectedMarketAddress as string]?.symbol

  return (
    <Listbox
      value={selectedMarketAddress}
      onChange={(v: any) => {
        !isBtnDisabled ? onSelectMarket(v) : undefined
      }}
    >
      {({ open }) => {
        if (!open) {
          setSearchQuery('')
          setAppliedTokenFilter('All')
        }
        return (
          <div className="relative">
            <Listbox.Button
              as="div"
              className={`flex h-12 w-full items-center justify-between rounded-md border border-th-input-border bg-th-bkg-2 px-4 ${
                isBtnDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
            >
              {selectedMarketAddress ? (
                <div className="flex items-center space-x-2">
                  <div className="App-card-title-info-icon">
                    <RenderTokenIcon symbol={indexSymbol} />
                  </div>
                  <p className="text-base font-bold uppercase leading-4 text-th-fgd-2">
                    {mapTokens[selectedMarketAddress]?.symbol}
                  </p>
                </div>
              ) : (
                <p className="text-sm font-medium text-th-fgd-3">
                  Select a token
                </p>
              )}

              <ArrowDown className={clsx(open && 'rotate-180')} />
            </Listbox.Button>

            {!isBtnDisabled && (
              <Listbox.Options>
                <Transition
                  className="absolute z-10 w-full border-b border-r border-th-input-border bg-th-bkg-5 p-4 pb-0 backdrop-blur-[50px]"
                  show={true}
                  appear={true}
                  as="div"
                  enter="ease-in duration-100 transition"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-out duration-100 transition"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <SearchInput
                    className="mb-3 h-11 !bg-th-bkg-3"
                    inputBoxClasses="h-11"
                    placeholder="Search Token"
                    value={searchQuery}
                    setValue={(e) => setSearchQuery(e.target.value)}
                  />

                  <TokenFilters
                    appliedFilter={appliedTokenFilter}
                    changeFilter={handleTokenFilterChange}
                    options={updateTokenFiltersCount}
                    forModal
                  />

                  <div className="mt-4 h-40 overflow-y-scroll">
                    {filteredMarkets.length ? (
                      filteredMarkets.map((item, index) => {
                        const { indexToken, indexTokenAddress, isSpotOnly } =
                          item

                        return (
                          <TokenCard
                            key={index}
                            isSpotOnly={isSpotOnly}
                            token={indexToken}
                            indexTokenAddress={indexTokenAddress}
                          />
                        )
                      })
                    ) : (
                      <div className="flex h-full items-center justify-center">
                        <EmptyState text="No Token found" />
                      </div>
                    )}
                  </div>
                </Transition>
              </Listbox.Options>
            )}
          </div>
        )
      }}
    </Listbox>
  )
}

const TokenCard = ({
  isSpotOnly,
  token,
  indexTokenAddress,
}: {
  isSpotOnly: boolean
  token: TokenData
  indexTokenAddress: string
}) => {
  const [tokenHoverState, setTokenHoverState] = useState(false)
  return (
    <Listbox.Option
      as="div"
      className={clsx(
        'flex h-12 cursor-pointer items-center justify-between border-b border-th-input-border px-4 last-of-type:border-b-0',
        tokenHoverState && 'bg-th-bkg-2',
      )}
      value={{ ...token, indexTokenAddress }}
      onMouseEnter={() => setTokenHoverState(true)}
      onMouseLeave={() => setTokenHoverState(false)}
    >
      <div className="flex items-center space-x-3">
        {!isSpotOnly && <RenderTokenIcon symbol={token.symbol} size="large" />}
        <div>
          <p
            className={clsx(
              'text-sm font-normal capitalize',
              tokenHoverState ? 'text-th-fgd-1' : 'text-th-fgd-3',
            )}
          >
            {token.symbol}
          </p>
          <p
            className={clsx(
              'text-sm font-semibold uppercase',
              tokenHoverState ? 'text-th-fgd-1' : 'text-th-fgd-2',
            )}
          >
            {token.name}
          </p>
        </div>
      </div>
      <div className="flex items-center space-x-2">
        <CopyToClipboard text={indexTokenAddress}>
          <Image
            alt="copy to clipboard"
            src="/icons/copy-icon.svg"
            height={16}
            width={16}
          />
        </CopyToClipboard>
        <p
          className={clsx(
            'text-sm font-normal leading-[18px]',
            tokenHoverState ? 'text-th-fgd-1' : 'text-th-fgd-3',
          )}
        >
          {getShorterAddress(indexTokenAddress || '', 4, 4, 4)}
        </p>
      </div>
    </Listbox.Option>
  )
}
