import Input from '@components/forms/Input'
import Button from '@components/shared/Button'
import { useCreatePoolStore } from '@store/permissionlessCreatePoolStore'
import tradeboxStore from '@store/tradeboxStore'
import SlippagePercentageSelector from 'components/Synthetics/TradeBox/SlippagePercentageSelector'
import { MarketsData } from 'domain/synthetics/markets'
import { ChangeEvent, useEffect, useMemo } from 'react'
import { useAccount } from 'wagmi'
import AddTokensLiquidity from './AddTokensLiquidity'

interface Props {
  nextStep: () => void
  prevStep: () => void
  marketsData: MarketsData
}

const Step2 = ({ nextStep, prevStep, marketsData }: Props) => {
  const {
    tokenA,
    poolName,
    setPoolName,
    firstTokenInputValue,
    secondTokenInputValue,
  } = useCreatePoolStore()

  const slippage = tradeboxStore((store) => store.slippage)
  const updateProp = tradeboxStore((store) => store.updateProp)
  const { isConnected } = useAccount()

  const selectedData = useMemo(
    () =>
      Object.values(marketsData).find(
        (item) => item.indexTokenAddress === tokenA,
      ),
    [marketsData, tokenA],
  )

  const handleSlippagePercentageChange = (val: number) => {
    updateProp('slippage', val)
  }

  useEffect(() => {
    if (selectedData) {
      const poolNameSuggestion = `${selectedData.indexToken?.symbol?.toUpperCase()} x ${selectedData.shortToken?.symbol?.toUpperCase()}`
      setPoolName(poolNameSuggestion)
    }
  }, [selectedData])

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="flex-1 space-y-6">
        <div className="space-y-2">
          <p className="text-sm font-medium leading-[17.5px] text-th-fgd-3">
            Tokens In Pool
          </p>
          <div className="flex h-12 w-full items-center rounded-md border border-th-input-border bg-th-bkg-2 px-4">
            <p className="text-base font-bold text-th-fgd-3">
              {selectedData?.indexToken?.symbol?.toUpperCase()}/
              {selectedData?.shortToken?.symbol?.toUpperCase()}
            </p>
          </div>
        </div>
        <div className="space-y-2">
          <p className="text-sm font-medium leading-[17.5px] text-th-fgd-3">
            Pool Name
          </p>
          <Input
            value={poolName}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setPoolName(e.target.value)
            }
            className="w-full border border-th-input-border !bg-th-bkg-2 !text-base text-th-fgd-2"
            placeholder=""
          />
        </div>
        <AddTokensLiquidity selectedData={selectedData} />

        <div className="mb-2 space-y-2">
          <p className="text-sm font-medium leading-[17.5px] text-th-fgd-3">
            Slippage
          </p>
          <SlippagePercentageSelector
            slippagePercentage={slippage}
            changeSlippagePercentage={handleSlippagePercentageChange}
          />
        </div>
      </div>
      <div className="flex items-center justify-between space-x-6">
        <Button
          size="large"
          secondary
          className="w-full border-th-fgd-3 hover:!border-th-fgd-3"
          onClick={prevStep}
        >
          <span className="text-sm font-bold leading-[18px] text-th-fgd-1">
            GO BACK
          </span>
        </Button>
        <Button
          size="large"
          className="w-full !bg-th-active"
          type="submit"
          onClick={nextStep}
          disabled={
            (!firstTokenInputValue && !secondTokenInputValue) || !isConnected
          }
        >
          <span className="text-sm font-bold leading-[18px] text-th-fgd-1">
            {!isConnected ? 'CONNECT WALLET' : 'NEXT STEP'}
          </span>
        </Button>
      </div>
    </div>
  )
}

export default Step2
